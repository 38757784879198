<template>
  <v-container class="pa-0">
    <v-row>
      <v-col cols="12" class="py-0">
        <template v-if="hasModel">
          <div
            data-cy="transportation-details-title"
            class="transportation-details-title"
          >
            {{ title }}
          </div>
        </template>

        <template v-else-if="isLoading">
          <v-skeleton-loader max-width="550" type="heading" />
        </template>
      </v-col>

      <v-col cols="12" md="8" class="px-6">
        <v-row>
          <v-col cols="12" class="wrapper">
            <template v-if="hasModel">
              <div class="wrapper-content">
                <TransportationInformationCards
                  v-intersect="{
                    handler: onIntersect,
                    options: {
                      threshold: intersectThreshold,
                      rootMargin: intersectRootMargin,
                    },
                  }"
                  :maxPassengers="maxPassengers"
                  :maxBags="maxBags"
                  :rideDuration="rideDuration"
                  :hasSafety="model.safetyMeasures"
                  ref="highlights-id"
                />
                <v-col cols="12" class="pb-6 pb-sm-10 pt-2 pt-sm-5 px-0">
                  <v-divider />
                </v-col>
                <TransportationOverviewCards
                  v-intersect="{
                    handler: onIntersect,
                    options: {
                      threshold: intersectThreshold,
                      rootMargin: intersectRootMargin,
                    },
                  }"
                  ref="overview-id"
                  :model="model"
                  :isLoading="isLoading"
                />
                <v-col cols="12" class="pt-8 pb-6 pb-sm-10 px-0">
                  <v-divider />
                </v-col>
                <TransportationMoreInformation :model="model" />
              </div>
            </template>
          </v-col>
        </v-row>

        <template v-if="$vuetify.breakpoint.mdAndDown">
          <v-row no-gutters class="mobile-wrapper-cta">
            <v-col cols="12">
              <div class="d-flex">
                <TransportationCtaCardContainer
                  :model="model"
                  :isLoading="isLoading"
                />
              </div>
            </v-col>
          </v-row>
        </template>
      </v-col>

      <template v-if="!$vuetify.breakpoint.mdAndDown">
        <v-col cols="4">
          <div
            class="sticky d-flex"
            :class="{ 'push-top': !$vuetify.breakpoint.lgAndDown }"
          >
            <TransportationCtaCardContainer
              :model="model"
              :isLoading="isLoading"
            />
          </div>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import TransportationCtaCardContainer from './TransportationCtaCardContainer.vue';
import TransportationOverviewCards from './TransportationOverviewCards';
import TransportationInformationCards from './TransportationInformationCards.vue';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';
import TransportationMoreInformation from '@/modules/product/transportation/features/Details/TransportationMoreInformation';

export default {
  name: 'transportation-product-details',
  components: {
    TransportationMoreInformation,
    TransportationOverviewCards,
    TransportationCtaCardContainer,
    TransportationInformationCards,
  },
  mixins: [GoogleTagManagerMixins, FlightsMixins],

  props: {
    model: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      highlightsActive: false,
      overviewActive: false,
      activeBlocks: [],
      intersectThreshold: [0, 0.2],
      intersectRootMargin: '-235px 0px 0px 0px',
    };
  },

  computed: {
    activeTabId() {
      let sortedTabs = this.activeBlocks
        .map((item) => ({
          id: item,
          order: this.menuNames.findIndex((menuItem) => menuItem.id === item),
        }))
        .sort((a, b) => a.order - b.order);
      return sortedTabs.length > 0 ? sortedTabs[0] : null;
    },

    hasModel() {
      return !!this.model;
    },

    title() {
      return this.hasModel
        ? this.model.title
        : this.$t('transportation.book_your_ride');
    },

    maxPassengers() {
      return this.hasModel ? this.model.maxPassengers : 0;
    },

    maxBags() {
      return this.hasModel ? this.model.maxBags : 0;
    },

    rideDuration() {
      //property doesn't exist yet, backend will include in their model
      return this.hasModel ? this.model.rideTimeMin : 0;
    },

    menuNames() {
      let array = [
        {
          id: 'highlights-id',
          title: this.$t('product.menu_names.highlights'),
          isShow: this.isHasHighlights,
          isActive: this.highlightsActive,
        },
        {
          id: 'overview-id',
          title: this.$t('product.menu_names.overview'),
          isShow: this.description ? true : false,
          isActive: this.overviewActive,
        },
      ];

      return array.filter((el) => el.isShow == true);
    },

    isHasHighlights() {
      return (
        this.maxPassengers != null ||
        this.maxBags != null ||
        (this.model &&
          this.model.safetyMeasures &&
          this.model.safetyMeasures.length > 0)
      );
    },

    description() {
      if (!this.hasModel) return null;
      const { description } = this.model;
      return description;
    },
  },

  methods: {
    intersectController(isIntersecting, blockName) {
      if (isIntersecting) {
        this.setActiveBlock(blockName);
      } else {
        this.removeActiveBlock(blockName);
      }
    },

    onIntersect(entries) {
      entries[0].target.classList.forEach((item) => {
        let blockName = this.getBlockName(item);
        if (blockName) {
          this.intersectController(entries[0].isIntersecting, blockName);
        }
      });
    },
    getBlockName(name) {
      const blockNamesToId = {
        highlights: 'highlights-id',
        overview: 'overview-id',
      };
      return blockNamesToId[name];
    },

    setActiveBlock(block) {
      let activeBlocks = [...this.activeBlocks, block];
      this.activeBlocks = Object.assign([], [...new Set(activeBlocks)]);
    },

    removeActiveBlock(block) {
      this.activeBlocks.splice(this.activeBlocks.indexOf(block), 1);
    },

    scrollToMenu(refName) {
      let element = this.$refs[refName].$el;
      if (!!element) {
        const y =
          element.getBoundingClientRect().top +
          window.pageYOffset -
          (this.$vuetify.breakpoint.mdAndDown ? 150 : 230);
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    },
  },

  watch: {
    hasModel() {
      if (this.hasModel) {
        this.pushProductRideClick([
          {
            vehicle_type: this.model.vehicleType,
            max_baggages: this.model.maxBags,
            max_passengers: this.model.maxPassengers,
            price: this.model.priceScale
              ? this.model.priceScale
              : this.model.priceStartingAt.value,
          },
        ]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  &.push-top {
    top: 180px;

    @media (max-width: map-get($grid-breakpoints, sm)) {
      top: 90px;
    }
  }
}

.mobile-wrapper-cta {
  max-width: 100%;
  position: fixed;
  bottom: -1px;
  left: 0;
  right: 0;
  width: 100%;
  background: #fff;
  z-index: 99;
}

.transportation-details-title {
  @include font-size(36, 150, 300);
  color: var(--v-grey9-base);

  @media (max-width: map-get($grid-breakpoints, sm)) {
    font-size: 24px;
  }
}

.product-details-section div {
  z-index: 2;
}

.page-navigation-sticky {
  position: sticky;
  top: 80px;
  background: white;
  z-index: 4;
  padding-bottom: 0;
  padding-top: 20px;

  @media (max-width: map-get($grid-breakpoints, sm)) {
    padding-bottom: 20px;
    padding-top: 12px;
  }

  &.push-top {
    top: 70px;

    @media (min-width: map-get($grid-breakpoints, md)) {
      top: 152px;
    }
  }

  &.is-v2 {
    top: 80px;
  }
}

.wrapper {
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (min-width: map-get($grid-breakpoints, sm)) {
    flex-direction: row;
    justify-content: space-between;
  }

  &-cta {
    display: none;
    min-width: 425px;
    max-width: min-content;

    @media (min-width: map-get($grid-breakpoints, sm)) {
      display: inline-table;
      position: -webkit-sticky;
      position: sticky;
      top: 50px;
    }
  }

  &-content {
    width: 100%;
    padding-right: 50px;

    @media (min-width: map-get($grid-breakpoints, md)) {
      flex-basis: 100%;
      padding-bottom: 100px;
    }

    @media (max-width: map-get($grid-breakpoints, md)) {
      padding-top: 0;
      padding-right: 0;
    }
  }
}
</style>
