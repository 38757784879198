<script>
import TransportationProductMixins from './TransportationProductMixins.vue';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
export default {
  name: 'TransportationProductDetailsMixins',
  mixins: [TransportationProductMixins],
  methods: {
    async loadTransportationDetails() {
      if (!this.hasTransportationDetails) {
        await this.$store.dispatch(
          `${this.storeModuleName}/loadTransportationDetails`,
          { transportationId: this.transportationId }
        );
        if (this.hasTransportationDetailsError) {
          this.$router.push({
            name: 'product-not-found',
            query: { link: 'transportationHome', name: 'transportation' },
          });
        }
      }
    },
  },
  computed: {
    hasTransportationDetails() {
      return this.$store.getters[
        `${this.storeModuleName}/hasTransportationDetails`
      ];
    },
    transportationDetails() {
      return this.$store.getters[
        `${this.storeModuleName}/transportationDetails`
      ];
    },
    hasTransportationDetailsError() {
      return (
        !!this.transportationDetails &&
        this.transportationDetails.error !== null
      );
    },
    transportationDetailsError() {
      return this.hasTransportationDetailsError
        ? this.transportationDetails.error
        : null;
    },
    transportationDetailsModel() {
      return this.hasTransportationDetails
        ? this.transportationDetails.value
        : null;
    },
    isLoadingTransportationDetails() {
      return (
        !!this.transportationDetails && this.transportationDetails.isLoading
      );
    },
    passengerNumber() {
      return this.$store.getters[`${this.storeModuleName}/passengers`];
    },
  },
};
</script>
