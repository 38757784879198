<template>
  <v-row no-gutters>
    <v-col no-gutters>
      <transition name="bounceInRight">
        <v-row v-if="hasDepartureLocation" class="flex-nowrap mb-1" no-gutters>
          <div class="icon-wrapper">
            <svg
              width="20"
              height="24"
              viewBox="0 0 20 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="
                  M14.7142 15.8806L11.1783 19.4164C11.0237 19.5712 10.8401 19.694 10.638
                  19.7778C10.4358 19.8616 10.2192 19.9047 10.0004 19.9047C9.78162 19.9047
                  9.56498 19.8616 9.36286 19.7778C9.16075 19.694 8.97713 19.5712 8.8225
                  19.4164L5.28583 15.8806C4.35352 14.9482 3.71861 13.7603 3.4614 12.4671C3.2042
                  11.1739 3.33624 9.83352 3.84083 8.61536C4.34543 7.39721 5.19991 6.35604
                  6.29623 5.62351C7.39255 4.89098 8.68147 4.5 10 4.5C11.3185 4.5 12.6074
                  4.89098 13.7038 5.62351C14.8001 6.35604 15.6546 7.39721 16.1592 8.61536C16.6638
                  9.83352 16.7958 11.1739 16.5386 12.4671C16.2814 13.7603 15.6465 14.9482
                  14.7142 15.8806V15.8806Z
                "
                :stroke="$vuetify.theme.themes.light.grey9"
                stroke-width="1.6"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="
                  M12.5 11.167C12.5 11.83 12.2366 12.4659 11.7678 12.9348C11.2989 13.4036
                  10.663 13.667 10 13.667C9.33696 13.667 8.70107 13.4036 8.23223 12.9348C7.76339
                  12.4659 7.5 11.83 7.5 11.167C7.5 10.504 7.76339 9.86807 8.23223 9.39922C8.70107
                  8.93038 9.33696 8.66699 10 8.66699C10.663 8.66699 11.2989 8.93038 11.7678
                  9.39922C12.2366 9.86807 12.5 10.504 12.5 11.167V11.167Z
                "
                :stroke="$vuetify.theme.themes.light.grey9"
                stroke-width="1.6"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <div class="size16-weight400 px-2">
            <b>{{ $t('from') }}:</b> {{ depratureLocation }}
          </div>
        </v-row>
      </transition>
      <transition name="bounceInRight">
        <v-row v-if="hasArrivalLocation" class="flex-nowrap mb-1" no-gutters>
          <div class="icon-wrapper">
            <svg
              width="20"
              height="24"
              viewBox="0 0 20 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="
                  M14.7142 15.8806L11.1783 19.4164C11.0237 19.5712 10.8401 19.694 10.638
                  19.7778C10.4358 19.8616 10.2192 19.9047 10.0004 19.9047C9.78162 19.9047
                  9.56498 19.8616 9.36286 19.7778C9.16075 19.694 8.97713 19.5712 8.8225
                  19.4164L5.28583 15.8806C4.35352 14.9482 3.71861 13.7603 3.4614 12.4671C3.2042
                  11.1739 3.33624 9.83352 3.84083 8.61536C4.34543 7.39721 5.19991 6.35604
                  6.29623 5.62351C7.39255 4.89098 8.68147 4.5 10 4.5C11.3185 4.5 12.6074
                  4.89098 13.7038 5.62351C14.8001 6.35604 15.6546 7.39721 16.1592 8.61536C16.6638
                  9.83352 16.7958 11.1739 16.5386 12.4671C16.2814 13.7603 15.6465 14.9482
                  14.7142 15.8806V15.8806Z
                "
                :stroke="$vuetify.theme.themes.light.grey9"
                stroke-width="1.6"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="
                  M12.5 11.167C12.5 11.83 12.2366 12.4659 11.7678 12.9348C11.2989 13.4036
                  10.663 13.667 10 13.667C9.33696 13.667 8.70107 13.4036 8.23223 12.9348C7.76339
                  12.4659 7.5 11.83 7.5 11.167C7.5 10.504 7.76339 9.86807 8.23223 9.39922C8.70107
                  8.93038 9.33696 8.66699 10 8.66699C10.663 8.66699 11.2989 8.93038 11.7678
                  9.39922C12.2366 9.86807 12.5 10.504 12.5 11.167V11.167Z
                "
                :stroke="$vuetify.theme.themes.light.grey9"
                stroke-width="1.6"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <div class="size16-weight400 px-2">
            <b>{{ $t('to') }}:</b> {{ arrivalLocation }}
          </div>
        </v-row>
      </transition>
      <transition name="bounceInRight">
        <v-row v-if="hasDate" class="flex-nowrap mb-1" no-gutters>
          <div class="icon-wrapper">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="
                  M5.66667 4.83333V1.5V4.83333ZM12.3333 4.83333V1.5V4.83333ZM4.83333
                  8.16667H13.1667H4.83333ZM3.16667 16.5H14.8333C15.2754 16.5 15.6993
                  16.3244 16.0118 16.0118C16.3244 15.6993 16.5 15.2754 16.5 14.8333V4.83333C16.5
                  4.39131 16.3244 3.96738 16.0118 3.65482C15.6993 3.34226 15.2754 3.16667 14.8333
                  3.16667H3.16667C2.72464 3.16667 2.30072 3.34226 1.98816 3.65482C1.67559 3.96738
                  1.5 4.39131 1.5 4.83333V14.8333C1.5 15.2754 1.67559 15.6993 1.98816 16.0118C2.30072
                  16.3244 2.72464 16.5 3.16667 16.5Z
                "
                :stroke="$vuetify.theme.themes.light.grey9"
                stroke-width="1.56"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="size16-weight400 px-2">{{ departureDate }}</div>
        </v-row>
      </transition>
      <transition name="bounceInRight">
        <v-row v-if="hasPerson" class="flex-nowrap" no-gutters>
          <div class="icon-wrapper">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="
                  M9 2.62833C9.44787 2.12059 10.0397 1.76126 10.6968 1.59816C11.354 1.43507
                  12.0451 1.47594 12.6785 1.71533C13.3118 1.95473 13.8572 2.38131 14.2421
                  2.9383C14.627 3.49529 14.8332 4.15629 14.8332 4.83333C14.8332 5.51038
                  14.627 6.17138 14.2421 6.72837C13.8572 7.28536 13.3118 7.71193 12.6785
                  7.95133C12.0451 8.19073 11.354 8.2316 10.6968 8.06851C10.0397 7.90541
                  .44787 7.54608 9 7.03833M11.5 16.5H1.5V15.6667C1.5 14.3406 2.02678 13.0688
                  2.96447 12.1311C3.90215 11.1935 5.17392 10.6667 6.5 10.6667C7.82608 10.6667
                  9.09785 11.1935 10.0355 12.1311C10.9732 13.0688 11.5 14.3406 11.5 15.6667V16.5ZM11.5
                  16.5H16.5V15.6667C16.5001 14.7889 16.2692 13.9266 15.8304 13.1664C15.3916
                  12.4062 14.7604 11.7749 14.0003 11.336C13.2402 10.8971 12.3779 10.666 11.5002
                  10.666C10.6224 10.6659 9.76015 10.897 9 11.3358M9.83333 4.83333C9.83333
                  5.71739 9.48214 6.56524 8.85702 7.19036C8.2319 7.81548 7.38405 8.16667 6.5
                  8.16667C5.61594 8.16667 4.7681 7.81548 4.14298 7.19036C3.51786 6.56524 3.16667
                  5.71739 3.16667 4.83333C3.16667 3.94928 3.51786 3.10143 4.14298 2.47631C4.7681
                  1.85119 5.61594 1.5 6.5 1.5C7.38405 1.5 8.2319 1.85119 8.85702 2.47631C9.48214
                  3.10143 9.83333 3.94928 9.83333 4.83333Z
                "
                :stroke="$vuetify.theme.themes.light.grey9"
                stroke-width="1.6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="size16-weight400 px-2">
            {{ $t('passengers') }}: {{ person }}
          </div>
        </v-row>
      </transition>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TransportationOrderInformation',
  props: {
    departureDate: {
      type: String,
      default: null,
    },
    person: {
      type: Number,
      default: null,
    },
    depratureLocation: { type: String, default: null },
    arrivalLocation: { type: String, default: null },
  },
  methods: {
    isEmptyOrNull(val) {
      return val === null || val === '';
    },
  },
  computed: {
    hasDate() {
      return !this.isEmptyOrNull(this.selectedDate);
    },
    hasPerson() {
      return this.person > 0;
    },
    hasDepartureLocation() {
      return !this.isEmptyOrNull(this.depratureLocation);
    },
    hasArrivalLocation() {
      return !this.isEmptyOrNull(this.arrivalLocation);
    },
  },
};
</script>

<style lang="scss" scoped>
.bounceInRight-enter-active {
  animation: bounceInRight 0.5s;
}
.bounceInRight-leave-active {
  animation: bounceInRight 0.5s reverse;
}
.icon-wrapper {
  display: flex;
  width: 20px;
  height: 20px;
  padding-top: 3px;
}
</style>
