<template>
  <v-container class="py-0">
    <v-row align="center" justify="start">
      <v-col cols="auto" class="pa-0 pr-2 d-flex align-center">
        <slot></slot>
      </v-col>
      <v-col cols="auto" justify="start" class="pa-0 d-flex justify-start">
        <p
          :class="['ma-0 feature-text', textBold ? 'feature-text-bold' : '']"
          :style="textStyle"
        >
          {{ text }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'feature-item',
  props: {
    text: { type: String, default: '' },
    textColor: { type: String, default: '#05090F' },
    textBold: { type: Boolean, default: false },
  },

  computed: {
    textStyle() {
      return { color: this.textColor };
    },
  },
};
</script>

<style lang="scss" scoped>
.feature-text {
  @include font-size(16, 24);
}

.feature-text-bold {
  font-weight: 600;
}
</style>
