<template>
  <v-row class="overview">
    <v-col cols="12" class="pb-0 pt-0">
      <TransportationTitle :title="$t('product.menu_names.more_information')" />
    </v-col>
    <v-col cols="12" class="pb-0 pt-0">
      <div v-if="hasModel">
        <TransportationFeaturedItems :featuredItems="featuresList" />
      </div>
      <v-skeleton-loader
        v-else-if="isLoading"
        type="sentences"
        max-width="400"
      />
    </v-col>
  </v-row>
</template>

<script>
import TransportationTitle from '@/modules/product/transportation/features/Details/TransportationSections/TransportationTitle.vue';
import TransportationFeaturedItems from '@/modules/product/transportation/features/Details/TransportationFeaturedItems.vue';

export default {
  name: 'TransportationMoreInformation',
  components: {
    TransportationFeaturedItems,
    TransportationTitle,
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    hasModel() {
      return !!this.model;
    },
    featuresList() {
      if (!this.hasModel) {
        return [];
      }

      const featuredItems = this.model.information.filter(
        (x) =>
          x.label !== this.$t('transportation.included_amenities') &&
          x.value !== this.$t('transportation.included') &&
          x.value !== this.$t('transportation.included_accepted')
      );
      return featuredItems;
    },
  },
};
</script>
