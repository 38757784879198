<template>
  <v-row class="d-flex justify-start highlights">
    <v-col cols="12" class="pb-0 pt-0 pt-md-7">
      <TransportationTitle :title="$t('product.menu_names.highlights')" />
    </v-col>
    <v-col cols="12" tag="ul" class="d-flex flex-wrap py-0 px-3">
      <v-col
        cols="12"
        tag="li"
        class="highlights-item pa-0 pr-10 mb-4 d-flex align-center"
        v-if="hasMaxPassengers"
      >
        <simple-svg
          width="24"
          height="24"
          :src="require(`@/assets/highlights/passengers.svg`)"
          stroke-class-name="stroke-to-change"
          stroke="var(--v-grey6-base)"
          customClassName="mr-3"
        />
        {{ maxPassengersText }}
      </v-col>
      <v-col
        cols="12"
        tag="li"
        class="highlights-item pa-0 pr-10 mb-4 d-flex align-center"
        v-if="hasMaxBags"
      >
        <simple-svg
          width="24"
          height="24"
          :src="require(`@/assets/highlights/bags.svg`)"
          stroke-class-name="stroke-to-change"
          stroke="var(--v-grey6-base)"
          customClassName="mr-3"
        />
        {{ maxBagsText }}
      </v-col>
      <v-col
        cols="12"
        tag="li"
        class="highlights-item pa-0 pr-10 mb-4 d-flex align-center"
        v-if="hasRideDuration"
      >
        <simple-svg
          width="24"
          height="24"
          :src="require(`@/assets/highlights/ride.svg`)"
          fill-class-name="fill-to-change"
          fill="var(--v-grey6-base)"
          customClassName="mr-3"
        />
        {{ rideDurationText }}
      </v-col>
      <v-col
        cols="12"
        tag="li"
        class="highlights-item pa-0 pr-10 mb-4 d-flex align-center"
        v-if="hasSafety && hasSafety.length"
      >
        <simple-svg
          width="24"
          height="24"
          :src="require(`@/assets/safety-shield.svg`)"
          stroke-class-name="stroke-to-change"
          stroke="var(--v-grey6-base)"
          customClassName="mr-3"
        />
        {{ $t('product.menu_names.health_safety_measures') }}
      </v-col>
    </v-col>
  </v-row>
</template>
<script>
import TransportationTitle from './TransportationSections/TransportationTitle';

export default {
  name: 'transportation-information-cards',
  components: { TransportationTitle },
  props: {
    maxPassengers: { type: Number, default: 0 },
    maxBags: { type: Number, default: 0 },
    rideDuration: { type: Number, default: 0 },
    hasSafety: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    hasMaxPassengers() {
      return !!this.maxPassengers && this.maxPassengers !== 99;
    },

    hasMaxBags() {
      return !!this.maxBags;
    },

    hasRideDuration() {
      return !!this.rideDuration;
    },

    maxPassengersText() {
      return this.$t('transportation.max_passengers_text', {
        maxPassengers: this.maxPassengers,
      });
    },

    maxBagsText() {
      return this.$t('transportation.max_bags_text', { maxBags: this.maxBags });
    },

    rideDurationText() {
      return this.$t('transportation.ride_duration_text', {
        duration: this.rideDuration,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.highlights {
  @media (max-width: map-get($grid-breakpoints, sm)) {
    margin-top: 0;
  }

  &-item {
    @include font-size(16, 24);
    color: var(--v-grey8-base);
  }

  &-text {
    margin-bottom: 56px;
    @include font-size(16, 150);
    color: var(--v-grey8-base);

    @media (max-width: map-get($grid-breakpoints, sm)) {
      padding-top: 0;
      margin-bottom: 48px;
    }
  }
}

.required {
  color: var(--v-primary-base);
}
</style>
