<template>
  <v-expansion-panels v-model="panel" flat class="summary-wrapper">
    <v-expansion-panel>
      <v-expansion-panel-header
        hide-actions
        class="px-3 py-3 d-flex justify-space-between"
      >
        <span class="summary-title size14-weight700">
          {{ $t('show_booking_details') }}
          <v-icon :color="$vuetify.theme.themes.light.primary.base">
            mdi-chevron-down
          </v-icon>
        </span>
        <span
          v-if="typeof panel == 'undefined'"
          class="summary-currency size20-weight700"
        >
          {{ total | currency(currency) }}
          {{ currency }}
        </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-container class="pa-0 ma-0 align-center" :class="{ loading }">
          <div class="d-flex align-center pb-3">
            <div v-if="!!thumbnail" class="mr-4 vehicle-image-wrapper">
              <v-img
                alt="Vehicle image"
                aspect-ratio="1"
                width="72"
                contain
                :src="thumbnail"
              />
            </div>
            <div>
              <div class="size18-weight600 mb-2">{{ vehicleType }}</div>
              <div>{{ vehicleModel }}</div>
            </div>
          </div>
          <TransportationOrderInformation
            :depratureLocation="depratureLocation"
            :arrivalLocation="arrivalLocation"
            :departureDate="departureDate"
            :person="passengers"
          />
          <v-divider class="my-4"></v-divider>
          <TransportationOrderItem
            :transportationItem="transportationItem"
            :discount="discount"
            :currency="currency"
          />
          <v-divider class="my-4"></v-divider>
          <v-row no-gutters class="flex-nowrap">
            <v-col>
              <v-row no-gutters justify="space-between">
                <div class="size16-weight400">{{ $t('total') }}:</div>
                <div class="size20-weight600">
                  {{ total | currency(currency) }}
                  {{ currency }}
                </div>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import TransportationOrderItem from './TransportationOrderItem.vue';
import TransportationOrderInformation from './TransportationOrderInformation.vue';
export default {
  name: 'TransportationOrderSummaryExpansion',
  components: { TransportationOrderItem, TransportationOrderInformation },
  props: {
    thumbnail: { type: String, default: null },
    title: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: String,
      default: 'USD',
    },
    transportationItem: {
      type: Object,
      default: null,
    },
    discount: {
      type: Object,
      default: null,
    },
    total: {
      type: Number,
      default: 0,
    },
    departureDate: {
      type: String,
      default: null,
    },
    depratureLocation: { type: String, default: null },
    arrivalLocation: { type: String, default: null },
    vehicleType: { type: String, default: null },
    vehicleModel: { type: String, default: null },
    passengers: { type: Number, default: null },
  },
  data() {
    return {
      panel: undefined,
    };
  },
};
</script>

<style lang="scss" scoped>
.loading {
  filter: blur(3px);
}

.image {
  flex: 1 0 auto;
  min-width: 100%;
  min-height: 100%;
}

.vehicle-model {
  @include font-size(16, 150);
  color: var(--v-grey7-base);
}

.vehicle-type {
  @include font-size(18, 150, 600);
  color: var(--v-grey9-base);
}

.total-price,
.total-text {
  letter-spacing: 0.04em;
  @include font-size(16, 22, 600);
}

.total-text {
  color: #857f72;
}

.notes {
  font-size: 14px;
}

.info-cta {
  user-select: none;
}

.location-image {
  height: 100px;
  width: 100px;
  margin-left: auto;
  border-radius: 4px;
  border: 1px solid #e4e8ef;
  box-shadow: 0px 3px 8px rgba(88, 98, 113, 0.15);
}

.location-title {
  flex: 1;
}

::v-deep.summary {
  &-wrapper {
    box-shadow: 0px 3px 8px rgb(88 98 113 / 15%);

    .v-expansion-panel {
      background: var(--v-grey1-base);
    }

    .v-expansion-panel-content__wrap {
      padding: 0 12px 16px;
    }
  }

  &-title {
    flex-grow: 100;
    color: var(--v-primary-base);
  }

  &-currency {
    color: var(--v-grey9-base);
  }
}

.vehicle-image-wrapper {
  border-radius: 4px;
}
</style>
