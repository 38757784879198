<template>
  <v-sheet class="options-cta-card">
    <v-container class="pa-0 options-cta-header">
      <v-row class="pa-5 mx-0">
        <v-col cols="12" class="pa-0">
          <span class="size24-weight700">
            {{ price.value | currency(price.baseCurrency) }}
            {{ price.baseCurrency }}
          </span>
        </v-col>
        <v-col cols="12" class="pa-0">
          <v-skeleton-loader
            max-width="550"
            type="text"
            :loading="loading"
            class="size14-weight700 per-person"
          >
            <span>{{ $t('product.price_per', { productType }) }}</span>
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>
    <v-container v-if="model" class="pt-6 px-5 pb-0">
      <OptionsTransportationDetails
        :model="model"
      ></OptionsTransportationDetails>
    </v-container>
    <v-container class="pa-0">
      <v-row no-gutters>
        <v-col>
          <v-btn
            tile
            :disabled="loading || !enableCta"
            color="primary"
            @click="selectOptionClick()"
            block
            data-cy="select-booking-option"
            x-large
          >
            <span class="size20-weight700">
              {{ buttonText }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import TransportationProductMixins from '@/modules/product/transportation/mixins/TransportationProductMixins';
import TransportationProductDetailsMixins from '@/modules/product/transportation/mixins/TransportationProductDetailsMixins';
import OptionsTransportationDetails from '@/modules/common/components/OptionsComponents/OptionsTransportationDetails';

export default {
  name: 'options-cta-card',
  mixins: [
    TransportationProductDetailsMixins,
    TransportationProductMixins,
    GoogleTagManagerMixins,
  ],
  props: {
    loading: {
      default: true,
      type: Boolean,
    },
    price: {
      default: () => ({ value: 200, baseCurrency: 'USD' }),
      type: Object,
    },
    freeCancellation: {
      default: true,
      type: Boolean,
    },
    instantConfirmation: {
      default: false,
      type: Boolean,
    },
    productType: {
      default: function() {
        return this.$t('product.person');
      },
      type: String,
    },
    buttonText: {
      default: function() {
        return this.$t('product.select_option');
      },
      type: String,
    },
    enableCta: {
      default: true,
      type: Boolean,
    },
    model: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      picker: null,
    };
  },
  components: { OptionsTransportationDetails },
  methods: {
    selectOptionClick() {
      this.pushProductRideSelect([
        {
          locations: this.model.locations,
          pickup_date: this.model.departureTime,
          passengers: this.passengerNumber,
          price: this.price.value,
        },
      ]);
      this.$emit('select-option-click');
    },
    formatDate(val) {
      return moment(new Date(val)).format('MMMM D, YYYY');
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.options {
  &-cta-header {
    background: #f9f9fb;
  }

  &-cta-card {
    margin-top: 28px;
    width: 100%;
    background: transparent;
    border-radius: 8px;
    overflow: hidden;
    border-color: #e8e6e1;
    box-sizing: border-box;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.16);
    .v-divider {
      border-width: 1px;
    }
    .from {
      color: #857f72;
    }
    .per-person {
      color: var(--v-grey7-base);
    }
    .secondary {
      fill: var(--v-secondary-base);
    }
    .primary {
      fill: var(--v-primary-base);
    }
    .cut-out {
      position: relative;
      left: -7px;
      top: -7px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: white;
      &.right {
        border-right: 2px solid #e8e6e1;
        border-top: 2px solid #e8e6e1;
        transform: rotate(45deg);
      }
      &.left {
        border-left: 2px solid #e8e6e1;
        border-bottom: 2px solid #e8e6e1;
        right: -7px;
        transform: rotate(45deg);
        left: unset;
      }
    }
  }
}
</style>
