<template>
  <TransportationProductDetails
    :model="transportationDetailsModel"
    :isLoading="transportationDetails.isLoading"
  />
</template>

<script>
import TransportationProductDetails from './features/Details/TransportationProductDetails';
import TransportationProductDetailsMixins from './mixins/TransportationProductDetailsMixins';

export default {
  name: 'ProductDetailsPage',
  components: { TransportationProductDetails },
  mixins: [TransportationProductDetailsMixins],
};
</script>
