<template>
  <CheckoutLayout>
    <template v-slot:breadcrumb>
      <v-row no-gutters>
        <router-link
          class="size14-weight400 d-flex align-center back-link"
          :class="{ 'text-truncate': $vuetify.breakpoint.mdAndDown }"
          :to="backRouteName"
        >
          <svg
            class="mr-3"
            width="9"
            height="14"
            viewBox="0 0 9 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.49935 12.8337L1.66602 7.00033L7.49935 1.16699"
              :stroke="$vuetify.theme.themes.light.primary.base"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>
            {{ backLinkText }}
          </span>
        </router-link>
      </v-row>
    </template>

    <template v-if="$vuetify.breakpoint.mdAndDown && searchTimestamp">
      <TimerCallout :timestamp="searchTimestamp" />
    </template>

    <template v-if="$route.name !== 'transportation-payment-status'">
      <div class="mt-6 mt-md-n11">
        <router-link :to="'booking-form'" class="back-link">
          <simple-svg
            width="14"
            height="12"
            :src="require(`@/assets/arrowLeft.svg`)"
          />
          <span class="ml-2">
            {{ $t('transportation.return_to_vehicle_details') }}
          </span>
        </router-link>
      </div>
    </template>

    <template v-if="$route.name !== 'transportation-payment-status'">
      <div
        class="
          d-flex
          flex-column flex-md-row
          align-start align-md-end
          justify-space-between
          booking-title
        "
      >
        <div class="booking-title-text mb-3 mb-md-0">
          {{ $t('booking.booking_text') }}
        </div>
        <ProgressIndicatorCheckoutContainer class="pb-2" :steps="steps" />
      </div>
      <v-divider class="mt-1 mb-7 mb-md-2" />
    </template>

    <router-view
      ref="travelerForm"
      @validate-booking="bookingValidated = $event"
      @loading-booking="
        (status) => {
          bookingLoading = status;
        }
      "
      @is-valid="buttonEnabled = $event"
      @onLoading="isLoading = $event"
    ></router-view>
    <template v-slot:progress v-if="!$vuetify.breakpoint.mdAndDown">
      <div
        class="d-flex flex-column order-info"
        :class="{
          'mt-n10': $route.name !== 'transportation-payment-status',
        }"
      >
        <div class="order-info_title">
          {{ $t('booking_details') }}
        </div>
      </div>
    </template>
    <template v-slot:summary>
      <template
        v-if="
          !$vuetify.breakpoint.mdAndDown &&
            $route.name !== 'transportation-payment-status'
        "
      >
        <v-btn
          color="primary"
          class="confirm-and-pay mb-8"
          justify="end"
          depressed
          block
          :height="58"
          :data-cy="
            $route.name === 'transportation-checkout'
              ? 'go-to-payment'
              : $route.name === 'transportation-payment'
              ? 'confirm-and-pay'
              : ''
          "
          :disabled="confirmAndPayDisabled"
          :loading="confirmAndPayIsLoading"
          @click="confirmAndPayAction"
        >
          <template v-if="$route.name === 'transportation-checkout'">
            {{ $t('proceed_to_payment') }}
          </template>

          <template v-if="$route.name === 'transportation-payment'">
            {{ $t('product.confirm_and_pay') }}
            {{ amount.value | currency(amount.baseCurrency) }}
            {{ amount.baseCurrency }}
          </template>
        </v-btn>
      </template>

      <template
        v-if="
          !$vuetify.breakpoint.mdAndDown &&
            $route.name === 'transportation-payment'
        "
      >
        <TermsAndConditionsLink />
      </template>

      <TransportationOrderSummaryContainer
        class="mt-n3 mx-n3 mb-3 mb-md-0 mx-md-0 mt-md-0"
      />
    </template>
    <template
      v-if="
        $vuetify.breakpoint.mdAndDown &&
          $route.name === 'transportation-payment' &&
          $route.name !== 'transportation-payment-status'
      "
    >
      <div class="px-5">
        <v-btn
          @click="confirmAndPayAction"
          :loading="confirmAndPayIsLoading"
          :disabled="confirmAndPayDisabled"
          data-cy="confirm-and-pay"
          block
          color="primary"
          class="confirm-and-pay mt-6 mb-8"
          justify="end"
          depressed
          :height="58"
        >
          {{ $t('product.confirm_and_pay') }}
          {{ amount.value | currency(amount.baseCurrency) }}
          {{ amount.baseCurrency }}
        </v-btn>
      </div>
    </template>
  </CheckoutLayout>
</template>

<script>
import TransportationProductDetailsMixins from './mixins/TransportationProductDetailsMixins';
import TransportationOrderSummaryContainer from './features/OrderSummary/TransportationOrderSummaryContainer';
import CheckoutLayout from '@/modules/product/features/CheckoutLayout/CheckoutLayout';
import ProgressIndicatorCheckoutContainer from '@/modules/product/booking/features/ProgressIndicator/ProgressIndicatorCheckoutContainer';
import ProductCheckoutMixins from '@/modules/product/mixins/ProductCheckoutMixins';
import TravelerMixins from '@/modules/common/mixins/TravelerMixins';
import GroundTransportationMixins from '@/modules/product/transportation/mixins/GroundTransportationMixins';
import TimerCallout from '@/modules/product/transportation/features/TimerCallout';
import TermsAndConditionsLink from '@/core/components/TermsAndConditionsLink';

export default {
  name: 'TransportationProductCheckoutPage',
  components: {
    TermsAndConditionsLink,
    TimerCallout,
    CheckoutLayout,
    TransportationOrderSummaryContainer,
    ProgressIndicatorCheckoutContainer,
  },

  mixins: [
    TransportationProductDetailsMixins,
    TravelerMixins,
    ProductCheckoutMixins,
    GroundTransportationMixins,
  ],

  data() {
    return {
      orderCreated: false,
      steps: [
        {
          name: this.$t('product.steps.names.contact_details'),
          status: 'PENDING',
          tag: 'transportation-checkout',
        },
        {
          name: this.$t('product.steps.names.review_payment'),
          status: 'PENDING',
          tag: 'transportation-payment',
        },
      ],
      errorMessage: null,
      bookingValidated: false,
      bookingLoading: false,
      buttonEnabled: false,
      isLoading: false,
    };
  },

  computed: {
    backRouteName() {
      const states = {
        payment: 'transportation-payment',
        traveler: 'transportation-checkout',
        availability: 'transportation',
      };
      const routeName = this.$route.name;
      return states[routeName] || '-1';
    },

    backLinkText() {
      return this.hasTransportationDetails
        ? this.transportationDetails.value.title
        : '';
    },

    confirmAndPayDisabled() {
      return this.$route.name === 'transportation-checkout'
        ? !this.buttonEnabled
        : this.$route.name === 'transportation-payment'
        ? !this.bookingValidated
        : false;
    },

    confirmAndPayIsLoading() {
      return this.$route.name === 'transportation-checkout'
        ? this.isLoading
        : this.$route.name === 'transportation-payment'
        ? this.bookingLoading
        : false;
    },
  },

  methods: {
    confirmAndPayAction() {
      if (
        this.$route.name === 'transportation-checkout' &&
        this.$route.name !== 'transportation-payment-status'
      ) {
        this.$refs.travelerForm.onClickProceedToPayment();
      }

      if (
        this.$route.name === 'transportation-payment' &&
        this.$route.name !== 'transportation-payment-status'
      ) {
        this.$refs.travelerForm.onPaymentButtonClick();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.back-link {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 16px;
  color: var(--v-grey8-base);
  text-decoration: none;
}

.order-info {
  width: 100%;

  &_title {
    padding-bottom: 24px;
    @include font-size(24, 30, 300);
    text-transform: uppercase;

    &-separator {
      margin-top: 7px !important;
    }
  }
}

.booking {
  &-title {
    @media (max-width: map-get($grid-breakpoints, 'md')) {
      flex-wrap: wrap;
      padding: 8px 0 0;
    }

    &-text {
      @include font-size(36, 150, 300);
      text-transform: uppercase;

      @media (max-width: map-get($grid-breakpoints, 'sm')) {
        font-size: 24px;
      }
    }
  }
}

.confirm-and-pay {
  @include font-size(16, 24, 700);
}
</style>
