<template>
  <v-container class="pa-0 options-card_wrapper">
    <v-row no-gutters>
      <v-col cols="12">
        <template v-if="$vuetify.breakpoint.mdAndDown">
          <div class="options-card_button" @click="show = true">
            <div class="options-card_text-half d-flex justify-start">
              {{ priceStartAt.value | currency(priceStartAt.baseCurrency) }}
              {{ priceStartAt.baseCurrency }}
            </div>
            <div class="options-card_text-half d-flex justify-end">
              {{ $t('transportation.review_and_book') }}
            </div>
            <div class="options-card_text-full d-flex justify-start">
              {{ $t('transportation.price_per_vehicle') }}
            </div>
          </div>
          <OptionsCtaCardModal
            v-model="show"
            :price="priceStartAt"
            :loading="isLoading"
            :freeCancellation="cancellation"
            :productType="productType"
            :buttonText="buttonText"
            :model="model"
            v-on:select-option-click="goToCheckout()"
          />
        </template>

        <template v-else>
          <OptionsCtaCard
            :price="priceStartAt"
            :loading="isLoading"
            :freeCancellation="cancellation"
            :productType="productType"
            :buttonText="buttonText"
            :model="model"
            @select-option-click="goToCheckout()"
          />
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import ProductDetailsMixins from '@/modules/product/booking/mixins/ProductDetailsMixins';
import OptionsCtaCard from '@/modules/product/features/OptionsCtaCard';
import OptionsCtaCardModal from '@/modules/product/features/OptionsCtaCardModal';

export default {
  name: 'transportation-cta-card-container',
  components: { OptionsCtaCard, OptionsCtaCardModal },
  mixins: [GoogleTagManagerMixins, ProductDetailsMixins],
  props: {
    model: { type: Object, default: null },
  },

  data() {
    return {
      productType: 'vehicle',
      buttonText: this.$t('transportation.book_this_ride'),
      show: false,
    };
  },

  computed: {
    priceStartAt() {
      if (!this.model) return { value: 0 };
      else {
        return this.model.priceStartingAt;
      }
    },

    cancellation() {
      return false;
    },
  },

  methods: {
    goToCheckout() {
      this.$router.push({ name: 'transportation-checkout' });
    },
  },
};
</script>

<style lang="scss" scoped>
.options-card {
  &_wrapper {
    width: 100%;
    background: #fff;
  }

  &_button {
    display: flex;
    flex-wrap: wrap;
    padding: 8px 20px;
    background: var(--v-primary-base);
    color: #fff;
  }

  &_text {
    &-half {
      flex-basis: 50%;
      @include font-size(16, 150, 700);
    }

    &-full {
      flex-basis: 100%;
      padding: 5px 0;
      @include font-size(12, 125, 400);
    }
  }
}
</style>
