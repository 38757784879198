<template>
  <v-container class="px-md-0">
    <v-row no-gutters>
      <v-col cols="12">
        <vue-headful :title="pageTitle" />

        <template v-if="displayNavBar && searchTimestamp">
          <TimerCallout :timestamp="searchTimestamp" />
        </template>

        <template v-if="hasTransportationDetailsError">
          <v-banner single-line :sticky="true" class="error-banner">
            <v-avatar slot="icon" color="error" size="40">
              <v-icon icon="mdi-lock" color="white">mdi-alert</v-icon>
            </v-avatar>
            <div class="title" v-if="transportationDetailsError.message">
              {{ transportationDetailsError.message }}
            </div>

            <template v-slot:actions>
              <v-btn text color="deep-purple accent-4" @click="refresh">
                {{ $t('refresh') }}
              </v-btn>
            </template>
          </v-banner>
        </template>

        <template v-else>
          <router-view></router-view>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import vueHeadful from 'vue-headful';
import TransportationProductMixins from './mixins/TransportationProductMixins';
import TransportationProductDetailsMixins from './mixins/TransportationProductDetailsMixins';
import GroundTransportationMixins from './mixins/GroundTransportationMixins';
import TimerCallout from './features/TimerCallout';

export default {
  name: 'transportation-product-page',
  components: { vueHeadful, TimerCallout },
  mixins: [
    GroundTransportationMixins,
    TransportationProductMixins,
    TransportationProductDetailsMixins,
  ],

  created() {
    this.registerStore();
    this.loadTransportationDetails();
  },

  methods: {
    refresh() {
      this.loadTransportationDetails();
    },
  },

  computed: {
    partnerName() {
      return this.$store.state.configs && this.$store.state.configs.title
        ? this.$store.state.configs.title
        : '';
    },

    pageTitle() {
      return this.hasTransportationDetails
        ? `${this.transportationDetailsModel.title} | ${this.partnerName} | Guestlogix`
        : '';
    },
    displayNavBar() {
      if (
        this.$route.name === 'transportation-checkout' ||
        this.$route.name === 'transportation-payment'
      ) {
        return !this.$vuetify.breakpoint.mdAndDown;
      }

      return this.$route.name !== 'transportation-payment-status';
    },
  },
};
</script>

<style lang="scss" scoped>
.error-banner {
  background: transparent;
  z-index: 3;
}
</style>
