<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-sheet class="options-cta-card fill-height">
      <v-container class="pa-0">
        <v-row
          no-gutters
          justify="space-between"
          align="center"
          class="options-cta-mobile-header px-5 py-3"
        >
          <v-col cols="auto"></v-col>
          <v-col cols="auto">
            <span class="options-cta-mobile-header-text">
              {{ $t('product.review_and_book') }}
            </span>
          </v-col>
          <v-col cols="auto">
            <v-btn icon small @click="closeDialog">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="pa-5 ma-0 options-cta-header">
          <v-col cols="12" class="pa-0">
            <span class="size24-weight700">
              {{ price.value | currency(price.baseCurrency) }}
              {{ price.baseCurrency }}
            </span>
          </v-col>
          <v-col cols="12" class="pa-0 pt-1">
            <v-skeleton-loader
              max-width="550"
              type="text"
              :loading="loading"
              class="size14-weight700 per-person"
            >
              <span>{{ $t('product.from_price_per', { productType }) }}</span>
            </v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
      <v-divider />
      <template v-if="model">
        <v-container class="pt-6 pb-5 px-5">
          <v-row no-gutters>
            <v-col cols="12">
              <OptionsTransportationDetails :model="model" />
            </v-col>
          </v-row>
        </v-container>
      </template>

      <v-spacer />
      <v-container class="pa-0">
        <v-row no-gutters>
          <v-col cols="12">
            <v-divider />
          </v-col>
        </v-row>

        <template v-if="model">
          <v-row no-gutters class="options-progress px-5">
            <v-col cols="12">
              <OptionsTransportationDetails :model="model" />
            </v-col>
          </v-row>
        </template>

        <v-row no-gutters class="additional-buttons">
          <v-col cols="12">
            <v-btn
              tile
              :disabled="loading || !enableCta"
              color="primary"
              @click="selectOptionClick()"
              block
              data-cy="select-booking-option"
              x-large
            >
              <span class="select-booking-option">
                {{ price.value | currency(price.baseCurrency) }} -
                {{ buttonText }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-dialog>
</template>

<script>
import OptionsTransportationDetails from '../../common/components/OptionsComponents/OptionsTransportationDetails.vue';

export default {
  name: 'options-cta-card-modal',
  components: { OptionsTransportationDetails },

  props: {
    value: Boolean,
    loading: {
      default: true,
      type: Boolean,
    },
    price: {
      default: () => ({ value: 200, baseCurrency: 'USD' }),
      type: Object,
    },
    freeCancellation: {
      default: true,
      type: Boolean,
    },
    instantConfirmation: {
      default: false,
      type: Boolean,
    },
    productType: {
      default: function() {
        return this.$t('product.person');
      },
      type: String,
    },
    buttonText: {
      default: function() {
        return this.$t('product.select_option');
      },
      type: String,
    },
    enableCta: {
      default: true,
      type: Boolean,
    },
    model: {
      default: () => {},
      type: Object,
    },
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    closeDialog() {
      this.show = false;
    },

    selectOptionClick() {
      this.$emit('select-option-click');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.options {
  &-cta-header {
    background: var(--v-grey1-base);
  }

  &-cta-mobile-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #fff;
    border-bottom: 1px solid var(--v-grey3-base);

    &-text {
      @include font-size(16, 150, 700);
    }
  }

  &-cta-card {
    width: 100%;
    max-width: 100%;
    height: 100%;
    margin: 0;
    background: #fff;
    overflow: auto;
    border-color: #e8e6e1;
    box-sizing: border-box;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.16);

    .v-divider {
      border-width: 1px;
    }

    .per-person {
      color: var(--v-grey7-base);
    }
  }
}

.select-booking-option {
  @include font-size(20, 150, 700);
}

.additional-buttons {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
</style>
