<template>
  <v-container class="ma-0 pa-0">
    <v-row class="ma-0 pb-5" v-for="(item, i) in featuredItems" :key="i">
      <p class="ma-0 featured-item-text">
        <span class="featured-item-text-bold">{{ item.label }}</span>
        {{ item.value }}
      </p>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'transportation-featured-items',
  props: {
    featuredItems: { type: Array, default: () => [] },
  },
};
</script>

<style lang="scss" scoped>
.featured-item-text {
  @include font-size(16, 24);
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: #05090f;
}

.featured-item-text-bold {
  font-weight: 500;
}
</style>
