<template>
  <v-card flat class="d-flex image-wrapper">
    <div class="d-flex align-center justify-center image-container">
      <v-img
        alt="vehicleImage"
        contain
        :src="vehicleImageUrl"
        max-height="60"
      />
    </div>
    <div class="d-flex justify-center flex-column">
      <v-card-title class="px-0 py-2 vehicle-model">
        {{ vehicleModel }}
      </v-card-title>
      <v-card-subtitle class="px-0 py-2 vehicle-type">
        {{ vehicleType }}
      </v-card-subtitle>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'transportation-vehicle-details',
  props: {
    vehicleModel: { type: String, default: '' },
    vehicleType: { type: String, default: '' },
    vehicleImageUrl: { type: String, default: '' },
  },
};
</script>

<style lang="scss" scoped>
.vehicle-model {
  @include font-size(18, 27, 600);
  color: var(--v-grey9-base);
}

.vehicle-type {
  @include font-size(16, 150);
  color: var(--v-grey8-base) !important;
}

.image {
  &-wrapper {
    padding: 12px 15px;
    background: #f9f9fb;
    border: 1px solid #e4e8ef;
    border-radius: 8px;
  }

  &-container {
    width: 200px;
    height: 80px;
    margin-right: 16px;
    background-color: white;
    border-radius: 4px;

    @media (max-width: map-get($grid-breakpoints, sm)) {
      width: 80px;
    }
  }
}
</style>
