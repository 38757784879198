<template>
  <v-container class="ma-0 pa-0">
    <v-row class="ma-0 pb-2" v-if="isCovidPrepared">
      <v-col>
        <FeatureItem
          :textColor="$vuetify.theme.themes.light.success"
          :text="$t('covid_prepared')"
        >
          <v-icon dense :color="$vuetify.theme.themes.light.success"
            >mdi-shield-outline</v-icon
          >
        </FeatureItem>
      </v-col>
    </v-row>
    <v-row class="ma-0 pb-2" v-for="(item, i) in noCovidItems" :key="i">
      <v-col>
        <FeatureItem :text="item">
          <v-icon dense :color="$vuetify.theme.themes.light.success">
            mdi-check
          </v-icon>
        </FeatureItem>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import FeatureItem from '../../../features/Details/FeatureItem.vue';
export default {
  name: 'transportation-included-items',
  components: { FeatureItem },
  props: {
    includedItems: { type: Array, default: () => [] },
  },
  computed: {
    isCovidPrepared() {
      const amenityCovidItem = this.includedItems.find(
        (x) => x === this.$t('covid_prepared')
      );
      return !!amenityCovidItem;
    },
    noCovidItems() {
      const items = this.includedItems.filter(
        (x) => x !== this.$t('covid_prepared')
      );
      return items;
    },
  },
};
</script>
