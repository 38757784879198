<template>
  <v-container
    class="pa-5 ma-0 summary-wrapper align-center"
    :class="{ loading }"
  >
    <v-row no-gutters>
      <v-col cols="12">
        <div class="d-flex align-center pb-3">
          <template v-if="!!thumbnail">
            <div class="vehicle-image-wrapper mr-4 mb-auto">
              <v-img
                alt="Vehicle image"
                contain
                width="72"
                aspect-ratio="1"
                :src="thumbnail"
              />
            </div>
          </template>

          <div>
            <div class="vehicle-type mb-2">{{ vehicleType }}</div>
            <div>{{ vehicleModel }}</div>
          </div>
        </div>
        <v-divider class="my-4" />
      </v-col>
      <v-col cols="12">
        <TransportationOrderInformation
          :depratureLocation="depratureLocation"
          :arrivalLocation="arrivalLocation"
          :departureDate="departureDate"
          :person="passengers"
        />
        <v-divider class="my-4" />
        <TransportationOrderItem
          :transportationItem="transportationItem"
          :discount="discount"
          :currency="currency"
        />
        <v-divider class="my-4" />
      </v-col>
    </v-row>

    <v-row no-gutters class="flex-nowrap">
      <v-col>
        <v-row no-gutters justify="space-between">
          <div class="total-price">
            {{ $t('total_price') }}
          </div>
          <div class="total-currency">
            {{ total | currency(currency) }}
            {{ currency }}
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TransportationOrderItem from './TransportationOrderItem.vue';
import TransportationOrderInformation from './TransportationOrderInformation.vue';
export default {
  name: 'TransportationOrderSummary',
  components: { TransportationOrderItem, TransportationOrderInformation },
  props: {
    thumbnail: { type: String, default: null },
    title: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: String,
      default: 'USD',
    },
    transportationItem: {
      type: Object,
      default: null,
    },
    discount: {
      type: Object,
      default: null,
    },
    total: {
      type: Number,
      default: 0,
    },
    departureDate: {
      type: String,
      default: null,
    },
    depratureLocation: { type: String, default: null },
    arrivalLocation: { type: String, default: null },
    vehicleType: { type: String, default: null },
    vehicleModel: { type: String, default: null },
    passengers: { type: Number, default: null },
  },
};
</script>

<style lang="scss" scoped>
.summary-wrapper {
  background: #f9f9f9;
  border-radius: 8px;
}

.vehicle-image-wrapper {
  border-radius: 4px;
}

.vehicle-type {
  @include font-size(18, 27, 600);
}

.total {
  &-price {
    @include font-size(16, 24, 400);
  }

  &-currency {
    @include font-size(20, 30, 700);
  }
}
</style>
