<template>
  <v-alert
    class="mb-6 mb-md-10 mt-3 mt-md-7 pa-3"
    text
    type="info"
    :color="$vuetify.theme.themes.light.primary.base"
    outlined
  >
    <span class="size16-weight400 black--text">
      {{
        $t('transportation.alert_message', {
          formattedTimeLeft: formattedTimeLeft,
        })
      }}
    </span>
  </v-alert>
</template>

<script>
import moment from 'moment';

export default {
  name: 'TimerCallout',
  props: ['timestamp'],

  mounted() {
    this.startTimer();
  },

  data() {
    let timeLimit = 300;
    if (this.timestamp) {
      timeLimit = this.calculateEndTime(this.timestamp, {
        m: 15,
      });
    }
    return {
      timePassed: 0,
      timerInterval: null,
      timeLimit: timeLimit,
    };
  },

  computed: {
    formattedTimeLeft() {
      const timeLeft = this.timeLeft;
      const minutes = Math.floor(timeLeft / 60);
      let seconds = timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${minutes}:${seconds}`;
    },

    timeLeft() {
      return this.timeLimit - this.timePassed;
    },

    timeFraction() {
      const rawTimeFraction = this.timeLeft / this.timeLimit;
      return rawTimeFraction - (1 / this.timeLimit) * (1 - rawTimeFraction);
    },
  },

  watch: {
    timeLeft(newValue) {
      if (newValue === 0) {
        this.onTimesUp();
      }
    },
  },

  methods: {
    onTimesUp() {
      clearInterval(this.timerInterval);
    },

    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
    },

    calculateEndTime(timestamp, duration) {
      const startTime = moment(timestamp);
      const nowTime = moment();
      const finishTime = startTime.add(duration);
      return finishTime.diff(nowTime, 's');
    },
  },
};
</script>
