<template>
  <h3 class="section-title mb-6">
    {{ title }}
  </h3>
</template>

<script>
export default {
  name: 'TransportationTitle',
  props: {
    title: {
      type: String,
      default: function() {
        return this.$t('product.details_sections.section_title');
      },
    },
  },
};
</script>

<style scoped lang="scss">
.section-title {
  position: relative;
  @include font-size(24, 36, 500);
  color: var(--v-grey9-base);

  @media (max-width: map-get($grid-breakpoints, sm)) {
    @include font-size(20, 27, 500);
  }
}
</style>
