<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-col class="pt-1">
        <template v-if="!!transportationItem">
          <v-row class="pt-0 flex-nowrap" no-gutters>
            <div class="badge">
              <div class="badge-quantity">
                {{ transportationItem.quantity }}
              </div>
            </div>

            <div class="size16-weight400">
              {{ transportationItem.title }}
            </div>
            <v-row no-gutters justify="end">
              <div class="size16-weight400">
                {{
                  (transportationItem.price * transportationItem.quantity)
                    | currency(currency)
                }}
              </div>
            </v-row>
          </v-row>
        </template>

        <template v-if="discount">
          <v-divider class="my-2" />
          <v-row no-gutters class="mt-3 justify-space-between">
            <div class="subtitle-1">
              {{ $t('discount_label_text') }} {{ discount.label }}
            </div>
            <div class="subtitle-1">
              {{ discount.discountAmount | currency(currency) }}
            </div>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'TransportationOrderItem',
  props: {
    currency: {
      type: String,
      default: 'USD',
    },
    transportationItem: {
      type: Object,
      default: null,
    },
    discount: {
      type: Object,
      default: null,
    },
  },

  computed: {
    discountExists() {
      return this.discount !== null;
    },

    discountValue() {
      if (!this.discount) return 0;
      return Math.abs(this.discount.discountAmount);
    },

    discountTermsAndCons() {
      if (!this.discount) return '';
      return this.discount.termsAndConditions;
    },
  },
};
</script>

<style lang="scss">
.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;

  .badge-quantity {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: var(--v-grey9-base);
  }
}

.discount {
  color: var(--v-grey9-base);
  font-style: normal;
  font-weight: normal;
}

.discount-message {
  color: black;
}
</style>
