<template>
  <v-row class="overview">
    <v-col cols="12" class="pb-0 pt-0">
      <TransportationTitle :title="$t('product.menu_names.overview')" />
    </v-col>
    <v-col cols="12" md="10" class="py-0 mb-6">
      <TransportationVehicleDetails
        v-if="hasModel"
        :vehicleModel="vehicleModel"
        :vehicleType="vehicleType"
        :vehicleImageUrl="vehicleImgUrl"
      />
    </v-col>
    <v-col cols="12" class="py-0">
      <h3 class="transportation-overview-item-title">
        {{ $t('product.menu_names.what_to_expect') }}
      </h3>
    </v-col>
    <v-col cols="12" class="pb-0">
      <div v-if="hasModel">
        <TransportationIncludedItems :includedItems="includedList" />
      </div>
      <v-skeleton-loader
        v-else-if="isLoading"
        type="sentences"
        max-width="400"
      />
    </v-col>
  </v-row>
</template>

<script>
import TransportationIncludedItems from './TransportationIncludedItems.vue';
import TransportationVehicleDetails from './TransportationVehicleDetails.vue';
import TransportationTitle from './TransportationSections/TransportationTitle.vue';

export default {
  name: 'TransportationOverviewCards',
  components: {
    TransportationVehicleDetails,
    TransportationIncludedItems,
    TransportationTitle,
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    hasModel() {
      return !!this.model;
    },
    vehicleModel() {
      return this.hasModel
        ? this.model.vehicleModel
        : this.$t('transportation.unknown_vehicle_model');
    },
    vehicleType() {
      return this.hasModel
        ? this.model.vehicleType
        : this.$t('transportation.unknown_vehicle_type');
    },
    vehicleImgUrl() {
      return this.hasModel && !!this.model.thumbnail
        ? this.model.thumbnail
        : require('@/assets/placeholder-image.jpg');
    },
    includedList() {
      if (!this.hasModel) {
        return [];
      }
      const amenityItem = this.model.information.find(
        (x) => x.label === this.$t('transportation.included_amenities')
      );
      const amenities = !!amenityItem ? amenityItem.value.split(', ') : [];
      const includes = this.model.information.reduce((filteredItems, item) => {
        if (item.value.toLowerCase().includes('included')) {
          filteredItems.push(item.label);
        }
        return filteredItems;
      }, []);
      const allIncludedItems = [...amenities, ...includes];
      return allIncludedItems;
    },
  },
};
</script>

<style lang="scss" scoped>
.transportation-overview {
  &-item-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    color: var(--v-grey9-base);
  }
}
</style>
