var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[(_vm.hasModel)?[_c('div',{staticClass:"transportation-details-title",attrs:{"data-cy":"transportation-details-title"}},[_vm._v(" "+_vm._s(_vm.title)+" ")])]:(_vm.isLoading)?[_c('v-skeleton-loader',{attrs:{"max-width":"550","type":"heading"}})]:_vm._e()],2),_c('v-col',{staticClass:"px-6",attrs:{"cols":"12","md":"8"}},[_c('v-row',[_c('v-col',{staticClass:"wrapper",attrs:{"cols":"12"}},[(_vm.hasModel)?[_c('div',{staticClass:"wrapper-content"},[_c('TransportationInformationCards',{directives:[{name:"intersect",rawName:"v-intersect",value:({
                  handler: _vm.onIntersect,
                  options: {
                    threshold: _vm.intersectThreshold,
                    rootMargin: _vm.intersectRootMargin,
                  },
                }),expression:"{\n                  handler: onIntersect,\n                  options: {\n                    threshold: intersectThreshold,\n                    rootMargin: intersectRootMargin,\n                  },\n                }"}],ref:"highlights-id",attrs:{"maxPassengers":_vm.maxPassengers,"maxBags":_vm.maxBags,"rideDuration":_vm.rideDuration,"hasSafety":_vm.model.safetyMeasures}}),_c('v-col',{staticClass:"pb-6 pb-sm-10 pt-2 pt-sm-5 px-0",attrs:{"cols":"12"}},[_c('v-divider')],1),_c('TransportationOverviewCards',{directives:[{name:"intersect",rawName:"v-intersect",value:({
                  handler: _vm.onIntersect,
                  options: {
                    threshold: _vm.intersectThreshold,
                    rootMargin: _vm.intersectRootMargin,
                  },
                }),expression:"{\n                  handler: onIntersect,\n                  options: {\n                    threshold: intersectThreshold,\n                    rootMargin: intersectRootMargin,\n                  },\n                }"}],ref:"overview-id",attrs:{"model":_vm.model,"isLoading":_vm.isLoading}}),_c('v-col',{staticClass:"pt-8 pb-6 pb-sm-10 px-0",attrs:{"cols":"12"}},[_c('v-divider')],1),_c('TransportationMoreInformation',{attrs:{"model":_vm.model}})],1)]:_vm._e()],2)],1),(_vm.$vuetify.breakpoint.mdAndDown)?[_c('v-row',{staticClass:"mobile-wrapper-cta",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('TransportationCtaCardContainer',{attrs:{"model":_vm.model,"isLoading":_vm.isLoading}})],1)])],1)]:_vm._e()],2),(!_vm.$vuetify.breakpoint.mdAndDown)?[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"sticky d-flex",class:{ 'push-top': !_vm.$vuetify.breakpoint.lgAndDown }},[_c('TransportationCtaCardContainer',{attrs:{"model":_vm.model,"isLoading":_vm.isLoading}})],1)])]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }