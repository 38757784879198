<script>
import TransportationStoreModule from '../store/index.js';
export default {
  name: 'TransportationProductMixins',
  computed: {
    transportationId() {
      return this.$route.params.id;
    },
    storeModuleName() {
      return !!this.transportationId
        ? `transportation/${this.transportationId}`
        : 'transportation';
    },
  },
  methods: {
    registerStore() {
      const store = this.$store;
      if (!TransportationStoreModule[`registered-${this.storeModuleName}`]) {
        store.registerModule(this.storeModuleName, TransportationStoreModule, {
          preserveState: !!store.state[this.storeModuleName],
        });
        TransportationStoreModule[`registered-${this.storeModuleName}`] = true;
      }
    },
  },
};
</script>
