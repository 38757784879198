<template>
  <div>
    <TransportationOrderSummaryExpansion
      v-if="$vuetify.breakpoint.mdAndDown"
      :transportationItem="transportationItem"
      :discount="discount"
      :total="total"
      :loading="isLoading"
      :departureDate="departureDate"
      :depratureLocation="depratureLocation"
      :arrivalLocation="arrivalLocation"
      :thumbnail="thumbnail"
      :vehicleType="vehicleType"
      :vehicleModel="vehicleModel"
      :title="title"
      :passengers="passengers"
      :currency="currency"
    />
    <TransportationOrderSummary
      v-else
      :transportationItem="transportationItem"
      :discount="discount"
      :total="total"
      :loading="isLoading"
      :departureDate="departureDate"
      :depratureLocation="depratureLocation"
      :arrivalLocation="arrivalLocation"
      :thumbnail="thumbnail"
      :vehicleType="vehicleType"
      :vehicleModel="vehicleModel"
      :title="title"
      :passengers="passengers"
      :currency="currency"
    />
  </div>
</template>

<script>
import TransportationOrderSummaryExpansion from './TransportationOrderSummaryExpansion.vue';
import TransportationOrderSummary from './TransportationOrderSummary.vue';
import moment from 'moment';
import TransportationProductDetailsMixins from '../../mixins/TransportationProductDetailsMixins.vue';
import ProductCheckoutMixins from '@/modules/product/mixins/ProductCheckoutMixins.vue';
export default {
  name: 'TransportationOrderSummaryContainer',
  components: {
    TransportationOrderSummary,
    TransportationOrderSummaryExpansion,
  },
  mixins: [TransportationProductDetailsMixins, ProductCheckoutMixins],
  computed: {
    thumbnail() {
      return this.hasTransportationDetails
        ? this.transportationDetails.value.thumbnail
        : null;
    },
    title() {
      return this.hasTransportationDetails
        ? this.transportationDetails.value.title
        : '';
    },
    isLoading() {
      return this.isLoadingTransportationDetails;
    },
    transportationItem() {
      return this.hasTransportationDetails
        ? {
            title: this.transportationDetails.value.title,
            quantity: 1,
            price: this.transportationDetails.value.priceStartingAt.value,
          }
        : null;
    },
    total() {
      if (!this.discount) {
        return this.hasTransportationDetails
          ? this.transportationDetails.value.priceStartingAt.value
          : null;
      }
      return this.discount.finalTotal;
    },
    currency() {
      if (!this.hasTransportationDetails) {
        return null;
      }
      return this.transportationDetails.value.priceStartingAt.baseCurrency;
    },
    departureDate() {
      if (!this.hasTransportationDetails) {
        return null;
      }
      const date = this.transportationDetails.value.departureTime;
      if (date) {
        return moment
          .utc(date)
          .utcOffset(this.transportationDetails.value.utcOffsetHours)
          .format('MMMM D, YYYY HH:mm')
          .toString();
      } else {
        return null;
      }
    },
    depratureLocation() {
      return this.hasTransportationDetails
        ? this.transportationDetails.value.locations[0].address
        : null;
    },
    arrivalLocation() {
      return this.hasTransportationDetails
        ? this.transportationDetails.value.locations[1].address
        : null;
    },
    vehicleType() {
      return this.hasTransportationDetails
        ? this.transportationDetails.value.vehicleType
        : null;
    },
    vehicleModel() {
      return this.hasTransportationDetails
        ? this.transportationDetails.value.vehicleModel
        : null;
    },
    passengers() {
      return this.passengerNumber;
    },
  },
};
</script>

<style></style>
