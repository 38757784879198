<template>
  <div class="pb-6">
    <div class="d-flex align-center pb-4 size16-weight600">
      <span class="mr-3">{{ $t('transportation.ride_details') }}</span>
      <span class="options-link">
        (
        <a
          class="d-flex align-center size14-weight600"
          @click="$router.push('/transportation')"
        >
          <simple-svg
            width="17"
            height="17"
            :src="require(`@/assets/open.svg`)"
            stroke-class-name="stroke-to-change"
            stroke="var(--v-primary-base)"
            customClassName="mr-1"
          />
          <span>
            {{ $t('edit') }}
          </span>
        </a>
        )
      </span>
    </div>
    <div class="size14-weight700 d-flex align-start pb-2">
      <div class="d-flex justify-center options-result-icon">
        <simple-svg
          width="20"
          height="24"
          :src="require(`@/assets/mapPoint.svg`)"
          stroke-class-name="stroke-to-change"
          stroke="var(--v-grey7-base)"
        />
      </div>
      <span class="ml-3 size16-weight400">
        <b>{{ $t('from') }} </b>{{ model.locations[0].address }},
      </span>
    </div>
    <div class="size14-weight700 d-flex align-start pb-2">
      <div class="d-flex justify-center options-result-icon">
        <simple-svg
          width="20"
          height="24"
          :src="require(`@/assets/mapPoint.svg`)"
          stroke-class-name="stroke-to-change"
          stroke="var(--v-grey7-base)"
        />
      </div>
      <span class="ml-3 size16-weight400">
        <b>{{ $t('to') }} </b>{{ model.locations[1].address }}
      </span>
    </div>
    <div class="size14-weight700 d-flex align-start pb-2">
      <div class="d-flex justify-center">
        <simple-svg
          width="20"
          height="24"
          :src="require(`@/assets/calendar-outline.svg`)"
          stroke-class-name="stroke-to-change"
          stroke="var(--v-grey7-base)"
        />
      </div>
      <span class="ml-3 size16-weight400">
        {{ formatDate(model.departureTime) }}
      </span>
    </div>
    <div class="size14-weight700 d-flex align-start">
      <div class="d-flex justify-center">
        <simple-svg
          width="20"
          height="24"
          :src="require(`@/assets/highlights/passengers.svg`)"
          stroke-class-name="stroke-to-change"
          stroke="var(--v-grey7-base)"
        />
      </div>
      <span class="ml-3 size16-weight400">
        {{ $t('passengers') }}: {{ passengerNumber }}
      </span>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import TransportationProductDetailsMixins from '@/modules/product/transportation/mixins/TransportationProductDetailsMixins.vue';

export default {
  name: 'OptionsSteps',
  props: ['model'],
  mixins: [TransportationProductDetailsMixins],
  methods: {
    formatDate(val) {
      return moment(new Date(val)).format('MMMM D, YYYY');
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.options {
  &-link {
    display: flex;
    gap: 2px;
  }

  &-result {
    & > div {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-icon {
      padding-top: 2px;
    }

    &-travelers {
      position: relative;

      &::after {
        content: '/';
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
}
</style>
