var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CheckoutLayout',{scopedSlots:_vm._u([{key:"breadcrumb",fn:function(){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('router-link',{staticClass:"size14-weight400 d-flex align-center back-link",class:{ 'text-truncate': _vm.$vuetify.breakpoint.mdAndDown },attrs:{"to":_vm.backRouteName}},[_c('svg',{staticClass:"mr-3",attrs:{"width":"9","height":"14","viewBox":"0 0 9 14","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M7.49935 12.8337L1.66602 7.00033L7.49935 1.16699","stroke":_vm.$vuetify.theme.themes.light.primary.base,"stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('span',[_vm._v(" "+_vm._s(_vm.backLinkText)+" ")])])],1)]},proxy:true},(!_vm.$vuetify.breakpoint.mdAndDown)?{key:"progress",fn:function(){return [_c('div',{staticClass:"d-flex flex-column order-info",class:{
        'mt-n10': _vm.$route.name !== 'transportation-payment-status',
      }},[_c('div',{staticClass:"order-info_title"},[_vm._v(" "+_vm._s(_vm.$t('booking_details'))+" ")])])]},proxy:true}:null,{key:"summary",fn:function(){return [(
        !_vm.$vuetify.breakpoint.mdAndDown &&
          _vm.$route.name !== 'transportation-payment-status'
      )?[_c('v-btn',{staticClass:"confirm-and-pay mb-8",attrs:{"color":"primary","justify":"end","depressed":"","block":"","height":58,"data-cy":_vm.$route.name === 'transportation-checkout'
            ? 'go-to-payment'
            : _vm.$route.name === 'transportation-payment'
            ? 'confirm-and-pay'
            : '',"disabled":_vm.confirmAndPayDisabled,"loading":_vm.confirmAndPayIsLoading},on:{"click":_vm.confirmAndPayAction}},[(_vm.$route.name === 'transportation-checkout')?[_vm._v(" "+_vm._s(_vm.$t('proceed_to_payment'))+" ")]:_vm._e(),(_vm.$route.name === 'transportation-payment')?[_vm._v(" "+_vm._s(_vm.$t('product.confirm_and_pay'))+" "+_vm._s(_vm._f("currency")(_vm.amount.value,_vm.amount.baseCurrency))+" "+_vm._s(_vm.amount.baseCurrency)+" ")]:_vm._e()],2)]:_vm._e(),(
        !_vm.$vuetify.breakpoint.mdAndDown &&
          _vm.$route.name === 'transportation-payment'
      )?[_c('TermsAndConditionsLink')]:_vm._e(),_c('TransportationOrderSummaryContainer',{staticClass:"mt-n3 mx-n3 mb-3 mb-md-0 mx-md-0 mt-md-0"})]},proxy:true}],null,true)},[(_vm.$vuetify.breakpoint.mdAndDown && _vm.searchTimestamp)?[_c('TimerCallout',{attrs:{"timestamp":_vm.searchTimestamp}})]:_vm._e(),(_vm.$route.name !== 'transportation-payment-status')?[_c('div',{staticClass:"mt-6 mt-md-n11"},[_c('router-link',{staticClass:"back-link",attrs:{"to":'booking-form'}},[_c('simple-svg',{attrs:{"width":"14","height":"12","src":require(`@/assets/arrowLeft.svg`)}}),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t('transportation.return_to_vehicle_details'))+" ")])],1)],1)]:_vm._e(),(_vm.$route.name !== 'transportation-payment-status')?[_c('div',{staticClass:"d-flex flex-column flex-md-row align-start align-md-end justify-space-between booking-title"},[_c('div',{staticClass:"booking-title-text mb-3 mb-md-0"},[_vm._v(" "+_vm._s(_vm.$t('booking.booking_text'))+" ")]),_c('ProgressIndicatorCheckoutContainer',{staticClass:"pb-2",attrs:{"steps":_vm.steps}})],1),_c('v-divider',{staticClass:"mt-1 mb-7 mb-md-2"})]:_vm._e(),_c('router-view',{ref:"travelerForm",on:{"validate-booking":function($event){_vm.bookingValidated = $event},"loading-booking":(status) => {
        _vm.bookingLoading = status;
      },"is-valid":function($event){_vm.buttonEnabled = $event},"onLoading":function($event){_vm.isLoading = $event}}}),(
      _vm.$vuetify.breakpoint.mdAndDown &&
        _vm.$route.name === 'transportation-payment' &&
        _vm.$route.name !== 'transportation-payment-status'
    )?[_c('div',{staticClass:"px-5"},[_c('v-btn',{staticClass:"confirm-and-pay mt-6 mb-8",attrs:{"loading":_vm.confirmAndPayIsLoading,"disabled":_vm.confirmAndPayDisabled,"data-cy":"confirm-and-pay","block":"","color":"primary","justify":"end","depressed":"","height":58},on:{"click":_vm.confirmAndPayAction}},[_vm._v(" "+_vm._s(_vm.$t('product.confirm_and_pay'))+" "+_vm._s(_vm._f("currency")(_vm.amount.value,_vm.amount.baseCurrency))+" "+_vm._s(_vm.amount.baseCurrency)+" ")])],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }