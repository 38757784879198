<template>
  <v-row no-gutters class="mb-6 mb-md-8 payment-terms">
    <v-col
      cols="12"
      class="py-0 size14-weight400"
      :class="[!isShopCheckout ? 'px-5 px-md-0' : '']"
    >
      {{ $t('product.terms_and_conditions_message') }}
      <a
        class="text-none no-hover size14-weight400 payment-terms-link ml-md-1"
        :href="termsAndConditionsUrl"
        target="_blank"
      >
        {{ $t('product.terms_and_conditions') }}
      </a>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TermsAndConditionsLink',
  props: {
    isShopCheckout: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    termsAndConditionsUrl() {
      const termsLink = this.$store.getters[`termsAndConditionsUrl`];
      return termsLink ? termsLink : '#';
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-terms {
  &-link {
    text-decoration: none;
    max-height: 20px;
    border-bottom: 1px solid var(--v-primary-base);
    border-radius: 0;
    letter-spacing: 0;
  }
}
</style>
